const CaseStudyIntro = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 mt-10 gap-4 flex flex-col justify-center text-black">
            <h2 className="text-3xl font-semibold capitalize tracking-tight">Our Case Studies.</h2>
            <div className="text-base font-medium sm:w-1/2 w-full">
               Our successful projects highlight our commitment to product delivery and excellence, crafting innovative and impactful solutions.
            </div>
        </div>
    );
}

export default CaseStudyIntro;