/*
Email template for Schedule a Call(same as contact Us.)
Hello Jibu Labs,


You got a message from:

Name:  {{from_name}}
Phone number: {{phone_number}}
company: {{company}}
Message For you: {{project_description}}

Best wishes,
Jibu Labs

*/

/*
Email Template for Ask for quatoation will remain same everywhere
*/

//4. validations

import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import emailjs from '@emailjs/browser';
import { Toast } from 'primereact/toast';

const CallUs = () => {
    const [visible, setIsVisible] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    const [phoneFieldError, setPhoneFieldError] = useState(false);
    const [emailFieldError, setEmailFieldError] = useState(false);
    const [firstNameFieldError, setFirstNameFieldError] = useState(false);
    const [companyFieldError, setCompanyFieldError] = useState(false);

    const toast = useRef(null);

    const headerElement = (
        <div className='flex flex-col'>
            <div className='text-xl'>Request a call</div>
            <div className='text-sm font-semibold'>One of our representatives will be in touch with you within 24 hours.</div>
        </div>
    );

    const quotationHeader = (
        <div className='flex flex-col'>
            <div className='text-lg font-bold'>Request a Quotation</div>
        </div>
    );

    const handleSubmitCall = (e) => {
        e.preventDefault();

        let hasError = false;

        if (!firstName) {
            hasError = true;
            setFirstNameFieldError(true);
        } else {
            setFirstNameFieldError(false);
        }

        if (!company) {
            hasError = true;
            setCompanyFieldError(true);
        } else {
            setCompanyFieldError(false);
        }

        if (!phone || !/^[\d+]+$/.test(phone)) {
            hasError = true;
            setPhoneFieldError(true);
        } else {
            setPhoneFieldError(false);
        }

        if (hasError) return;

        const serviceId = 'service_59lk0na';
        const templateId = 'template_1c9dusa'; 
        const userId = 'SRMiVIwbh3UlybTBy';
        
        const templateParams = {
            from_name: `${firstName} ${lastName}`,
            phone_number: phone,
            company: company,
            project_description: 'Schedule a Call',
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then((response) => {
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setCompany('');
                setIsVisible(false);

                toast.current.show({ severity: 'success', summary: 'Success', detail: 'We received your message and will connect shortly.', life: 3000 });
            })
            .catch((error) => {
                alert('Error sending email:', error);
            });
    };

    const handleSubmitQuotation = (e) => {
        e.preventDefault();

        let hasError = false;

        if (!firstName) {
            hasError = true;
            setFirstNameFieldError(true);
        } else {
            setFirstNameFieldError(false);
        }

        if (!phone || !/^[\d+]+$/.test(phone)) {
            hasError = true;
            setPhoneFieldError(true);
        } else {
            setPhoneFieldError(false);
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            hasError = true;
            setEmailFieldError(true);
        } else {
            setEmailFieldError(false);
        }

        if (!selectedService || !projectDescription) {
            hasError = true;
        }

        if (hasError) return;

        const serviceId = 'service_59lk0na';
        const templateId = 'template_1c9dusa'; 
        const userId = 'SRMiVIwbh3UlybTBy';


        const templateParams = {
            from_name: `${firstName} ${lastName}`,
            phone_number: phone,
            from_email: email,
            selected_service: selectedService,
            project_description: projectDescription,
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then((response) => {
                console.log('Email sent successfully!', response);
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setEmail('');
                setSelectedService('');
                setProjectDescription('');
                setVisibleDrawer(false);

                toast.current.show({ severity: 'success', summary: 'Success', detail: 'We received your message and will connect shortly.', life: 3000 });
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    return (
        <div className="flex flex-col justify-center gap-0">
            <Toast ref={toast} /> {/* Render the Toast component */}
            <div className="w-full lg:px-12 px-6 flex flex-col justify-center text-white text-sm">
                <div className="bg-amber-500 h-3"></div>
            </div>
            <div className="bg-gray-100 sm:py-20 py-12 grid sm:grid-cols-2 grid-cols-1 items-center">
                <div className="w-full lg:px-12 p-6 flex flex-col justify-center sm:gap-6 gap-4 text-dark text-sm">
                    <h2 className="sm:text-4xl text-3xl font-semibold tracking-tight">Talk to us about your upcoming project. We can help!</h2>
                    <div className="text-base font-medium w-full">
                        Don't hesitate to get in touch with us! Whether you have a project in mind or simply want to learn more about our services, our team is ready to help.
                    </div>
                </div>
                <div className="flex flex-row gap-4 justify-center items-center">
                    <button className='bg-dark rounded-lg px-5 py-3 text-white font-semibold tracking-tight' onClick={() => setIsVisible(true)}>
                        Schedule a call
                    </button>
                    <Dialog header={headerElement} visible={visible} modal className="sm:mx-0 mx-4 sm:w-6/12" onHide={() => { if (!visible) return; setIsVisible(false); }} >
                        <div className='flex flex-col items-center w-full gap-4'>
                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 w-full">
                                <input 
                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-6 py-2" 
                                    placeholder="First Name" 
                                    value={firstName} 
                                    onChange={(e) => setFirstName(e.target.value)} 
                                    required 
                                />
                                <input 
                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-6 py-2" 
                                    placeholder="Last Name" 
                                    value={lastName} 
                                    onChange={(e) => setLastName(e.target.value)} 
                                    required 
                                />
                                {firstNameFieldError && <div className="text-red-500 text-xs">*First Name Required</div>}
                            </div>
                            
                            <div className="grid sm:grid-cols-1 gap-2 w-full">
                                <input 
                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-6 py-2" 
                                    placeholder="Company" 
                                    value={company} 
                                    onChange={(e) => setCompany(e.target.value)} 
                                    required 
                                />
                                {companyFieldError && <div className="text-red-500 text-xs">*Company Required</div>}
                            </div>
                            
                            <div className="grid sm:grid-cols-1 gap-2 w-full">
                                <input 
                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-6 py-2" 
                                    placeholder="Phone Number" 
                                    value={phone} 
                                    onChange={(e) => setPhoneNumber(e.target.value)} 
                                    required 
                                />
                                {phoneFieldError && <div className="text-red-500 text-xs">*Phone Number can only contain numbers and + sign.</div>}
                            </div>
                            <div className="grid sm:grid-cols-1 gap-2 w-full">
                                <button className='bg-dark rounded-lg px-5 py-3 text-white font-semibold tracking-tight' onClick={handleSubmitCall}>
                                    Submit
                                </button>
                            </div>
                            <div className='text-sm text-center w-full sm:w-5/6 font-semibold'>*We do not share private information with any third party. For more details view our <a href='/privacy-policy' target='__blank' className='text-amber-600'>privacy policy.</a> and <a href='/terms-of-service' target='__blank' className='text-amber-600'>terms of service.</a> </div>
                        </div>
                    </Dialog>
                    <Sidebar className='sm:w-4/12 w-10/12' header={quotationHeader} visible={visibleDrawer} position="center" onHide={() => setVisibleDrawer(false)}>
                        <div className='flex flex-col items-center w-full gap-4'>
                            <div className="grid sm:grid-cols-1 gap-2 w-full">
                                <div className='flex flex-col gap-2'>
                                    <div className='text-sm font-semibold'>Full Name</div>
                                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 w-full">
                                        <input 
                                            className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                            placeholder="First Name" 
                                            value={firstName} 
                                            onChange={(e) => setFirstName(e.target.value)} 
                                            required 
                                        />
                                        <input 
                                            className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                            placeholder="Last Name" 
                                            value={lastName} 
                                            onChange={(e) => setLastName(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                    {firstNameFieldError && <div className="text-red-500 text-xs">*First Name Required</div>}
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <div className='text-sm font-semibold'>Phone Number</div>
                                    <div className="grid grid-cols-1 gap-2 w-full">
                                        <input 
                                            className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                            placeholder="Phone Number" 
                                            value={phone} 
                                            onChange={(e) => setPhoneNumber(e.target.value)} 
                                            required 
                                        />
                                        {phoneFieldError && <div className="text-red-500 text-xs">*Phone Number can only contain numbers and + sign.</div>}
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <div className='text-sm font-semibold'>Email Address</div>
                                    <div className="grid grid-cols-1 gap-2 w-full">
                                        <input 
                                            className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                            placeholder="Email Address" 
                                            value={email} 
                                            onChange={(e) => setEmail(e.target.value)} 
                                            required 
                                        />
                                        {emailFieldError && <div className="text-red-500 text-xs">*Please enter a valid email address (e.g., demo@gmail.com).</div>}
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <div className='text-sm font-semibold'>Choose a service</div>
                                    <div className="grid grid-cols-1 gap-2 w-full">
                                        <select 
                                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2 text-sm" 
                                            value={selectedService} 
                                            onChange={(e) => setSelectedService(e.target.value)} 
                                            required
                                        >
                                            <option disabled value="">Choose a service</option>
                                            <option value="web">Web Development</option>
                                            <option value="mobile">Mobile App Development</option>
                                            <option value="erp">Enterprise Software</option>
                                            <option value="ui/ux">UI/UX Design</option>
                                            <option value="branding">Brand Design</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 w-full mt-2'>
                                    <textarea 
                                        className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-4 py-2 h-32 text-sm" 
                                        placeholder="Describe your project" 
                                        value={projectDescription} 
                                        onChange={(e) => setProjectDescription(e.target.value)} 
                                    ></textarea>
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 gap-2 w-full mt-2">
                                <button className='bg-dark rounded-lg px-5 py-3 text-white font-semibold tracking-tight text-sm' onClick={handleSubmitQuotation}>
                                    Request Quotation
                                </button>
                            </div>
                        </div>
                    </Sidebar>
                    <button onClick={() => setVisibleDrawer(true)} className='border border-dark bg-white rounded-lg px-5 py-3 text-black font-semibold tracking-tight'>
                        Request a Quotation
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CallUs;
