import FaqHeader from "./Components/FAQ/Header";
import FaqsList from "./Components/FAQ/faqs";
import Awards from "./Components/Welcome/Awards";
import ContactUs from "./Components/Welcome/ContactUs";

const FAQ = () => {
    return (
        <div className="select-none">
            <FaqHeader />
            <div className="flex flex-col items-center py-12">
                <div className="sm:w-5/6 w-full sm:px-4 flex-col items-center">
                    <FaqsList />
                </div>
            </div>
            <Awards />
            <ContactUs />
        </div>
    );
}

export default FAQ;