const CompanyHeader = () => {
    return (
        <div className="w-full mx-auto p-6 lg:px-12 sm:py-14 pt-4 pb-20 flex flex-col justify-center text-white text-sm bg-dark">
            <div className="sm:text-4xl text-3xl font-semibold sm:w-4/6 w-full">
                Your tech innovator building transformative technology solution.
            </div>
            <div className="relative mt-12">
                <div className="w-1/2 bg-amber-500 absolute h-3 -top-3 right-5"></div>
                <img src="/images/office.png" alt="Office" className="pointer-events-none w-full"></img>
                <div className="w-1/2 bg-amber-500 absolute h-3 -bottom-3 right-5"></div>
            </div>
        </div>
    )
}

export default CompanyHeader