import Button from "../Elements/Button";

const ServicesHeader = () => {

    const toContactUs = () => {
        console.log('Redirect to contact us');
    }

    return (
        <div className="w-full mx-auto p-6 lg:px-12 sm:py-14 pt-4 pb-20 gap-6 flex flex-col justify-center text-white text-sm bg-dark">
            <div className="font-normal uppercase">Digital Product Development</div>
            <div className="sm:text-4xl text-3xl font-semibold sm:w-5/6 w-full">
                Transform your concepts into tangible realities through our established digital product development service.
            </div>
            <div className="text-base">Experience innovative, user-friendly digital products tailored to your target market and stand ahead of the competition at every step.</div>
            <a href="/contact-us" className="block">
                <Button label="Tell us about your project" onClick={toContactUs}></Button>
            </a>
        </div>
    )
}

export default ServicesHeader