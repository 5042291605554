import Commitments from "./Components/Company/Commitments";
import FoundingStory from "./Components/Company/FoundingStory";
import CompanyHeader from "./Components/Company/Header";
import Team from "./Components/Company/Team";
import Values from "./Components/Company/Values";
import VisionMission from "./Components/Company/VisionMission";
import Awards from "./Components/Welcome/Awards";
import CallUs from "./Components/Welcome/CallUs";

const Company = () => {
    return (
        <div className='select-none'>
        <CompanyHeader/>
        <VisionMission/>
        <Commitments/>
        <Values/>
        <FoundingStory/>
        <Team/>
        <Awards/>
        <CallUs/>
      </div>
    );
}

export default Company;