import team from './images/team-illustration.png';

const Values = () => {
    return (
        <div className="flex flex-col justify-center gap-0">
            <div className="w-full lg:px-12 px-6 flex flex-col justify-center">
                <div className="bg-amber-500 h-3"></div>
            </div>
            <div className="bg-gray-100 pt-16 grid sm:grid-cols-2 grid-cols-1 items-start">
                <div className="w-full lg:px-12 p-4 flex flex-col justify-center sm:gap-6 gap-4 text-dark text-sm">
                    <h2 className="text-3xl font-semibold tracking-tight">Our Values</h2>
                    <div className='text-base'>Our <strong>LIVE</strong> values promote a culture of growth, collaboration and customer-centricity within our company.</div>
                    <div className="text-base font-medium w-full">
                        <div className="flex flex-nowrap items-center gap-4 pb-4">
                            <div className="sm:text-5xl text-6xl font-bold flex justify-center w-16">L</div>
                            <div className="text-sm">is for <strong>LEAD</strong>. We emphasize the importance of visionary and proactive leadership in guiding our company towards success.</div>
                        </div>
                        <div className="flex flex-nowrap items-center gap-4 pb-4">
                            <div className="sm:text-5xl text-6xl font-bold flex justify-center w-16">I</div>
                            <div className="text-sm">is for <strong>INNOVATE</strong>. We encourage continuous improvement and are willing to embrace change and new ideas.</div>
                        </div>
                        <div className="flex flex-nowrap items-center gap-4 pb-4">
                            <div className="sm:text-5xl text-6xl font-bold flex justify-center w-16">V</div>
                            <div className="text-sm">is for <strong>VALUE</strong>. We focus on creating value for customers, employees, partners, and other stakeholders.</div>
                        </div>
                        <div className="flex flex-nowrap items-center gap-4">
                            <div className="sm:text-5xl text-6xl font-bold flex justify-center w-16">E</div>
                            <div className="text-sm">is for <strong>Engage</strong>. We promote active and meaningful engagement with customers, employees, and the wider community.</div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={team} alt='team' className='pointer-events-none' style={{"width": "500px", "height": "auto"}}></img>
                </div>
            </div>
        </div>
    );
}

export default Values;