import { Accordion, AccordionTab } from 'primereact/accordion';
import { useState } from 'react';

const FaqsList = () => {

    const [tabs] = useState([
        {
            header: 'What sets Jibulabs apart from other digital creative agencies?',
            children: <p className="m-0">Jibulabs stands out for its multidisciplinary approach, combining expertise in website development, mobile app development, UI/UX design, and ERP development under one roof. We prioritize innovation and tailored solutions to meet your unique business needs.</p>
        },
        {
            header: 'How does Jibulabs ensure that our website/mobile app/ERP system aligns with our business goals and objectives?',
            children: <p className="m-0">We conduct in-depth consultations to understand your business objectives, target audience, and long-term vision. Our solutions are meticulously crafted to align with your goals, ensuring optimal performance and return on investment.</p>
        },
        {
            header: 'What is your approach to UI/UX design, and how do you ensure a seamless user experience?',
            children: <p className="m-0">Our UI/UX design process is driven by user research, iterative testing, and a focus on intuitive interfaces. We prioritize user satisfaction and engagement, ensuring a seamless experience across all digital platforms.</p>
        },
        {
            header: 'Can Jibulabs handle both front-end and back-end development for our project?',
            children: <p className="m-0">Yes, we specialize in full-stack development, encompassing both front-end and back-end aspects. From user interface design to database management and server-side scripting, we have the expertise to deliver end-to-end solutions.</p>
        },
        {
            header: 'How do you ensure the security and scalability of the solutions you develop?',
            children: <p className="m-0">Security and scalability are paramount in our development process. We employ robust cybersecurity measures and leverage scalable technologies to safeguard your data and future-proof your solutions against evolving business needs.</p>
        },
        {
            header: 'What ongoing support and maintenance services does Jibulabs offer post-launch?',
            children: <p className="m-0">Our post-launch support includes bug fixes, updates, performance monitoring, and ongoing maintenance to ensure your digital solutions remain optimized and secure. We're committed to providing comprehensive support throughout your journey.</p>
        },
        {
            header: 'Can you provide examples of successful projects similar to ours that Jibulabs has completed?',
            children: <p className="m-0">Absolutely, our portfolio showcases a diverse range of successful projects tailored to various industries and business requirements. We invite you to explore our past work to gain insights into our capabilities and expertise.</p>
        },
        {
            header: 'What is the typical timeline for completing a project with Jibulabs?',
            children: <p className="m-0">Our project timelines vary depending on the scope and complexity of the project. However, we strive to provide transparent timelines during the initial consultation phase and work diligently to meet agreed-upon deadlines while maintaining the highest quality standards.</p>
        },
        {
            header: 'How does Jibulabs handle communication and collaboration throughout the project?',
            children: <p className="m-0">Communication is key to our project management approach. We utilize various communication channels, such as email, phone calls, video conferences, and project management tools, to ensure seamless collaboration and keep you updated on project progress every step of the way.</p>
        },
        {
            header: 'Does Jibulabs offer custom solutions tailored to our specific business needs?',
            children: <p className="m-0">Absolutely, we pride ourselves on delivering bespoke solutions tailored to each client's unique requirements. Our team works closely with you to understand your business challenges, objectives, and preferences, ensuring that the final product aligns perfectly with your vision and goals.</p>
        },
    ]);

    const createDynamicTabs = () => {
        return tabs.map((tab, i) => {
            return (
                <AccordionTab className='text-base leading-6' key={tab.header} header={tab.header} disabled={tab.disabled}>
                    {tab.children}
                </AccordionTab>
            );
        });
    };

    return (
        <Accordion activeIndex={0}>{createDynamicTabs()}</Accordion>
    );
}

export default FaqsList;