import googleCloud from './images/google-cloud.png';
import microsoft from './images/microsoft.png';
import aws from './images/aws.png';
import trustpilot from './images/trustpilot.png';

const Partners = () => {
    return (
        <div className="border-t border-[#1e1b4b] bg-dark grid sm:grid-cols-2 grid-cols-1">
            <div className="w-full mx-auto p-6 lg:px-12 py-10 gap-6 flex flex-col justify-center text-white text-sm">
                <div className="font-bold text-sm">Official Partners</div>
                <div className="flex flex-row items-center sm:gap-12 gap-8">
                    <img src={aws} className="pointer-events-none w-auto h-7" alt="AWS"></img>
                    <img src={microsoft} className="pointer-events-none w-auto h-7" alt="Microsoft"></img>
                    <img src={googleCloud} className="pointer-events-none w-auto h-7" alt="Google Cloud"></img>
                </div>
            </div>
            <div className="w-full mx-auto p-6 lg:px-12 py-10 gap-6 flex flex-col justify-center text-white text-sm">
                <div className="font-bold text-sm">Recognized by</div>
                <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">
                    <img src={trustpilot} className="pointer-events-none w-auto h-7" alt="AWS"></img>
                </div>
            </div>
        </div>
    );
}

export default Partners;