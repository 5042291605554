import ContactForm from "./Components/ContactUs/ContactForm";
import PrivacyHeader from "./Components/Privacy/Header";
import PrivacyPolicy from "./Components/Privacy/Privacy";
import Awards from "./Components/Welcome/Awards";

const Privacy = () => {
    return (
        <div className="select-none">
            <PrivacyHeader />
            <PrivacyPolicy />
            <Awards />
            <ContactForm />
        </div>
    );
}

export default Privacy;