import { PrimeReactProvider } from 'primereact/api';
import { Outlet } from "react-router-dom";
import Navigation from "./Components/Navigation";
import Footer from "./Components/Footer";

export default function Layout() {
    return (
        <PrimeReactProvider>
            <Navigation></Navigation>
            <Outlet></Outlet>
            <Footer></Footer>
        </PrimeReactProvider>
    )
}