import { useState } from "react";

const TestimonialCard = (props) => {
    return (
        <div className="bg-gray-100 sm:p-10 p-6 flex flex-col gap-8">
            <div className="flex flex-nowrap items-center justify-between">
                <div className="flex flex-nowrap items-center gap-2">
                    <div className="font-bold text-xl">{props.rating.toFixed(1)}</div>
                    <div className="flex flex-row items-center flex-nowrap">
                        {[...Array(5).keys()].map((_, index) => (
                            <div key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 ${index < props.rating ? 'text-orange-400' : 'text-gray-600'}`}>
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="border border-gray-600 py-1 px-4 bg-white text-xs font-bold rounded-full">
                    {props.tag}
                </div>
            </div>
            <div className="text-base sm:h-36">{props.feedback}</div>
            <div className="border-t border-gray-200"></div>
            <div className="flex flex-col gap-1">
                <div className="text-lg font-semibold">{props.client}</div>
                <div className="text-sm text-gray-400">{props.role}</div>
            </div>
        </div>
    );
}

const TestimonialsTabs = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabs = ['On-site Reviews', 'Trustpilot Reviews'];

    const testimonials =
        [
            {
                'rating': 5,
                'tag': 'Website Redesign',
                'feedback': 'Our vision for the new site only came to life through our incredible partnership with Jibu Labs founded by Madona S. Wambua. Without their collaboration, support, and expertise (and sometimes hand-holding me through it - shout out to Winnie and Leonard!), this would not have been possible. We are so grateful for all your hard work and look forward to working together on our next project together!',
                'client': 'Sierra O\'Bryan',
                'role': 'Director of Technology for getWITit National',
            },
            {
                'rating': 5,
                'tag': 'Website Development',
                'feedback': 'Jibu Labs captured the essence of Ladora Cuisine flawlessly, creating a visually stunning and user-friendly platform. Their dedication and skill transformed my vision into an online culinary destination that truly delights visitors worldwide.',
                'client': 'Ladora',
                'role': 'Founder of Ladora Cuisine',
            },
        ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    }

    return (
        <div className="w-full mx-auto lg:px-12 p-4 pt-20 gap-4 flex flex-col justify-center">
            <div className="w-full flex sm:gap-4 gap-2 items-center justify-center rounded-lg p-1">
                {tabs.map((tab, index) => (
                    <div key={index} className={`flex flex-col gap-0 ${index === 0 ? 'items-end' : 'items-start'}`}>
                        <button
                            key={index}
                            onClick={() => handleTabClick(index)}
                            className={`flex flex-nowrap sm:text-2xl text-xl items-center pb-2 rounded capitalize font-extrabold ${activeTab === index ? 'text-dark' : 'text-gray-200'}`}
                        >
                            {tab}
                        </button>
                        <div className={`h-1 w-2/3 ${activeTab === index ? 'bg-dark' : 'bg-gray-200'}`}></div>
                    </div>

                ))}
            </div>
            <div className="text-black flex flex-col gap-6 items-center justify-center">
                {activeTab === 0 && <div className="grid sm:grid-cols-2 sm:gap-4 gap-2 mt-6">
                    {testimonials.map((item, index) => (
                        <TestimonialCard
                            key={index}
                            rating={item.rating}
                            tag={item.tag}
                            feedback={item.feedback}
                            client={item.client}
                            role={item.role}
                        ></TestimonialCard>
                    ))}

                </div>}
                {activeTab === 1 && <div className="mt-6 flex flex-col items-center gap-4 justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:w-12 sm:h-12 h-8 w-8 text-gray-200">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                    <div className="text-base text-gray-500">No trustpilot reviews found!</div>
                </div>}
            </div>
            <div className="flex items-center justify-center mt-4">
                <div className="text-xs font-semibold sm:w-1/2 w-full text-center">
                    As part of our process, we ask our clients to provide honest feedback about their experience working with us. A reputable third-party, Trustpilot, conducts these reviews and feedback sessions by phone or online.
                </div>
            </div>
        </div>
    );
}

export default TestimonialsTabs;