const CommitmentsHeader = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 pt-12 pb-20 gap-4 flex flex-col justify-center bg-dark text-white">
            <div className="sm:text-4xl text-3xl font-semibold sm:w-1/2 w-full">
                Our dedication to individuals, communities, and the environment.
            </div>
            <div className="text-base sm:w-3/4 w-full">Jibu Labs is dedicated to making a positive difference in the lives of our team members, the communities where we operate, and the health of our planet. We've set clear goals in each of these areas to support a vibrant workforce, uplift our local communities, and promote environmental stewardship.</div>
        </div>
    );
}

export default CommitmentsHeader;