import Careers from "./Components/Commitments/Careers";
import CommitmentsHeader from "./Components/Commitments/Header";
import SocioEnvironment from "./Components/Commitments/SocioEnvironment";
import TeamBenefits from "./Components/Commitments/TeamBenefits";
import Awards from "./Components/Welcome/Awards";
import CallUs from "./Components/Welcome/CallUs";

const Commitments = () => {
    return (
        <div className="select-none">
            <CommitmentsHeader/>
            <TeamBenefits/>
            <SocioEnvironment/>
            <Careers/>
            <Awards/>
            <CallUs/>
        </div>
    );
}

export default Commitments;