const ServicesIntro = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 mt-10 gap-4 flex flex-col justify-center text-black">
            <h2 className="text-3xl font-semibold tracking-tight">Building end-to-end digital solutions.</h2>
            <div className="text-base font-medium sm:w-1/2 w-full">
                We navigate your product development journey with a forward-thinking mindset and strategic roadmaps, ensuring efficient, sustainable, and successful outcomes.
            </div>
        </div>
    );
}

export default ServicesIntro;