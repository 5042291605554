const ServicesDescription = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 mt-10 gap-4 flex flex-col justify-center text-black">
            <h2 className="text-3xl font-semibold tracking-tight sm:w-1/2 w-full">Optimize Your Business with Digital Product Development Solutions.</h2>
            <div className="text-base font-medium sm:w-2/3 w-full">
                Access scalable digital solutions from expert teams covering the entire development lifecycle—ideation, prototyping, launch, and ongoing maintenance—equipping you for success in the digital realm.
            </div>
        </div>
    );
}

export default ServicesDescription;