const FaqHeader = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 pt-12 pb-20 gap-4 flex flex-col justify-center bg-dark text-white">
            <div className="sm:text-4xl text-3xl font-semibold sm:w-1/2 w-full">
                Frequently Asked Questions
            </div>
            <div className="text-base sm:w-3/4 w-full">Get answers to common questions about our services, processes, and commitment to digital excellence. Find clarity for your digital projects. Need more? Reach out - we're here to help!</div>
        </div>
    );
}

export default FaqHeader;