import flag from './images/flag.png';
import rocket from './images/rocket.png';
import strategize from './images/strategize.png';

const Commitments = () => {
    return (
        <div className="w-full mx-auto px-4 pb-16 gap-12 sm:px-12 grid sm:grid-cols-3 grid-cols-1">
            <div className="flex flex-col gap-4 items-center">
                <img src={flag} alt='Empower' className='sm:w-44 w-32 h-auto pointer-events-none'></img>
                <div className="text-2xl font-bold">Empower</div>
                <div className="text-center sm:w-3/4 w-full">Empower millions by democratizing technology, fundamentally transforming society for the better.</div>
            </div>
            <div className="flex flex-col gap-4 items-center">
                <img src={strategize} alt='Empower' className='sm:w-44 w-32 h-auto pointer-events-none'></img>
                <div className="text-2xl font-bold">Strategize</div>
                <div className="text-center sm:w-3/4 w-full">Leverage our strategic expertise to guide startups and enterprises through the trial and implementation phases of Industry 4.0.</div>
            </div>
            <div className="flex flex-col gap-4 items-center">
                <img src={rocket} alt='Empower' className='sm:w-44 w-32 h-auto pointer-events-none'></img>
                <div className="text-2xl font-bold">Accelerate</div>
                <div className="text-center sm:w-3/4 w-full">Advance AI, Blockchain, & HCI through global research collaborations with top universities and labs, accelerating innovation.</div>
            </div>
        </div>
    );
}

export default Commitments;