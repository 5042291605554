const Careers = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 py-14 gap-4 flex flex-col justify-center border-b border-gray-200 text-black">
            <div className="flex flex-col gap-8 items-center">
                <div className="flex flex-col gap-4">
                    <h2 className="text-3xl font-semibold tracking-tight">Careers</h2>
                    <div className="text-base font-normal w-full">
                        Whether you're a seasoned professional or just starting your career journey, we offer a range of positions that provide opportunities for growth, learning, and making a meaningful impact. Join us in shaping the future of technology and be part of a collaborative, innovative, and inclusive workplace culture. Browse our current openings below and start your journey with us today!
                    </div>
                    <div className="flex flex-col items-center sm:pt-12 pt-6 gap-6">
                        <div className="text-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-20 h-20">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                        </div>
                        <div className="text-base font-semibold">No open vacancies at the moment!</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Careers;