import Button from "../Elements/Button";
import Feature from "../Elements/Feature";

const Hero = () => {

    return (
        <div className="w-full mx-auto p-6 lg:px-12 sm:py-14 pt-10 pb-20 flex flex-col justify-center text-white text-sm bg-dark">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 sm:gap-0">
                <div className="flex flex-col items-start gap-6">
                    <div className="uppercase font-medium text-xs tracking-wide">a multidisciplinary digital creative lab</div>
                    <div className="flex flex-col">
                        <h2 className="font-semibold tracking-tight text-5xl">Elevated confidence</h2>
                        <h2 className="font-semibold tracking-tight text-5xl">in tailored software</h2>
                        <h2 className="font-semibold tracking-tight text-5xl text-amber-500">design and development</h2>
                    </div>
                    <div className="font-normal lg:text-lg md:text-md sm:text-sm">
                        For organizations aiming to spearhead transformative initiatives,<br></br> we bring your technological aspirations to life.
                    </div>
                    <a href="/contact-us">
                        <Button label="Tell us about your project"></Button>
                    </a>
                </div>
                <div className="flex flex-col items-center">
                    <div className="sm:w-2/3 w-full flex flex-col gap-4">
                        <Feature title="USA office with global development centers"></Feature>
                        <Feature title="Client centric approach"></Feature>
                        <Feature title="Excellent staff augmentation for startups"></Feature>
                        <Feature title="4.9/5.0 customer satisfaction rating"></Feature>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero