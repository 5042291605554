// using emailjs.com


//1. create account on emailjs.com
//2. connect jibu labs email account to it
//3. install 2 libraries: @emailjs/browser and axios-> npm install @emailjs/browser axios
//4. set email template as follows:

/*
Hello Jibu Labs,

 Quotation request from:

Name: {{from_name}}

Phone Number: {{phone_number}}

Email: {{from_email}}

Selected Service: {{selected_service}}

Project Description: {{project_description}}

 

 Best wishes,

 Jibu Labs
 */

//new code validations
import React, { useState, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import emailjs from '@emailjs/browser';
import { Toast } from 'primereact/toast';

const ServiceCard = (props) => {
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneFieldError, setPhoneFieldError] = useState(false);
    const [emailFieldError, setEmailFieldError] = useState(false);
    const [firstNameFieldError, setFirstNameFieldError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    const toast = useRef(null);

    const quotationHeader = (
        <div className='flex flex-col'>
            <div className='text-lg font-bold'>Request a Quotation</div>
        </div>
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        let hasError = false;

        if (!firstName) {
            hasError = true;
            setFirstNameFieldError(true);
        } else {
            setFirstNameFieldError(false);
        }

        if (!firstName || !email || !phoneNumber || !selectedService || !projectDescription) {
            hasError = true;
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            hasError = true;
            setEmailFieldError(true);
        } else {
            setEmailFieldError(false);
        }

        if (!/^[\d+]+$/.test(phoneNumber)) {
            hasError = true;
            setPhoneFieldError(true);
        } else {
            setPhoneFieldError(false);
        }

        if (hasError) return;

        const serviceId = 'service_59lk0na';
        const templateId = 'template_s0ju88d'; 
        const userId = 'SRMiVIwbh3UlybTBy';

        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'Jibu Labs',
            phone_number: phoneNumber,
            selected_service: selectedService,
            project_description: projectDescription,
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then((response) => {
                setName('');
                setEmail('');
                setPhoneNumber('');
                setSelectedService('');
                setProjectDescription('');
                setVisibleDrawer(false);

                toast.current.show({ severity: 'success', summary: 'Success', detail: 'We received your message and will connect shortly.', life: 3000 });
            })
            .catch((error) => {
                alert('Error sending email:', error);
            });
    }

    return (
        <div className="relative">
            <Toast ref={toast} /> {/* Render the Toast component */}
            <div className={`bg-[#F3E7D9] rounded sm:px-16 sm:py-10 p-6`}>
                <div className="flex flex-row items-center sm:py-16 py-6">
                    <div className={`text-black flex flex-col sm:w-1/2`}>
                        <h2 className="sm:text-3xl text-2xl font-semibold">{props.name}</h2>
                        <div className="border-2 border-black sm:w-1/6 w-full mt-2"></div>
                        <div className="font-normal sm:text-base text-sm w-full mt-6">{props.description}</div>
                        <div className="font-semibold text-base pt-6">{props.categories}</div>
                        <div className="block pt-6 my-2">
                            <Sidebar className='sm:w-4/12 w-10/12' header={quotationHeader} visible={visibleDrawer} position="Center" onHide={() => setVisibleDrawer(false)}>
                                <div className='flex flex-col items-center w-full gap-4'>
                                    <div className="grid sm:grid-cols-1 gap-2 w-full">
                                        <div className='flex flex-col gap-2'>
                                            <div className='text-sm font-semibold'>Full Name</div>
                                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 w-full">
                                                <input 
                                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                                    placeholder="First Name" 
                                                    value={firstName} 
                                                    onChange={(e) => setFirstName(e.target.value)} 
                                                    required 
                                                />
                                                <input 
                                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                                    placeholder="Last Name" 
                                                    value={name} 
                                                    onChange={(e) => setName(e.target.value)} 
                                                    required 
                                                />
                                            </div>
                                            {firstNameFieldError && <div className="text-red-500 text-xs">*First Name Required</div>}
                                        </div>
                                        <div className='flex flex-col gap-2 w-full'>
                                            <div className='text-sm font-semibold'>Phone Number</div>
                                            <div className="grid grid-cols-1 gap-2 w-full">
                                                <input 
                                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                                    placeholder="Phone Number" 
                                                    value={phoneNumber} 
                                                    onChange={(e) => setPhoneNumber(e.target.value)} 
                                                    required 
                                                />
                                            </div>
                                            {phoneFieldError && <div className="text-red-500 text-xs">*Phone Number can only contain numbers and + sign.</div>}
                                        </div>
                                        <div className='flex flex-col gap-2 w-full'>
                                            <div className='text-sm font-semibold'>Email Address</div>
                                            <div className="grid grid-cols-1 gap-2 w-full">
                                                <input 
                                                    className="bg-gray-100 border border-gray-200 outline-amber-500 rounded w-full px-4 py-2 text-sm" 
                                                    placeholder="Email Address" 
                                                    value={email} 
                                                    onChange={(e) => setEmail(e.target.value)} 
                                                    required 
                                                />
                                            </div>
                                            {emailFieldError && <div className="text-red-500 text-xs">*Please enter a valid email address (e.g., demo@gmail.com).</div>}
                                        </div>
                                        <div className='flex flex-col gap-2 w-full'>
                                            <div className='text-sm font-semibold'>Choose a Service</div>
                                            <div className="grid grid-cols-1 gap-2 w-full">
                                                <select 
                                                    className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2 text-sm" 
                                                    value={selectedService} 
                                                    onChange={(e) => setSelectedService(e.target.value)} 
                                                    required
                                                >
                                                    <option disabled value="">Choose a service</option>
                                                    <option value="web">Web Development</option>
                                                    <option value="mobile">Mobile App Development</option>
                                                    <option value="erp">Enterprise Software</option>
                                                    <option value="ui/ux">UI/UX Design</option>
                                                    <option value="branding">Brand Design</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-2 w-full mt-2'>
                                            <textarea 
                                                className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-4 py-2 h-32 text-sm" 
                                                placeholder="Describe your project" 
                                                value={projectDescription} 
                                                onChange={(e) => setProjectDescription(e.target.value)} 
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="grid sm:grid-cols-1 gap-2 w-full mt-2">
                                        <button 
                                            className='bg-dark rounded-lg px-5 py-3 text-white font-semibold tracking-tight text-sm' 
                                            onClick={handleSubmit}
                                        >
                                            Request Quotation
                                        </button>
                                    </div>
                                </div>
                            </Sidebar>

                            <button 
                                onClick={() => setVisibleDrawer(true)} 
                                className='bg-dark rounded-lg px-5 py-3 text-white font-semibold tracking-tight'
                            >
                                Ask for Quotation
                            </button>
                        </div>
                    </div>
                    <img src={props.mock} className={`sm:block hidden absolute ${props.mockBottom} w-auto pointer-events-none`} alt="mockup" />
                </div>
            </div>
        </div>
    );
}


const ServicesList = () => {
    const services = [
        {
            'name': 'Website development',
            'description': 'Uncover a seamless, engaging user experience with swift, intuitive, visually captivating web applications or Progressive Web Apps, driving business growth and bolstering your bottom line.',
            'categories': 'Business Websites | Commercial Websites | E-Commerce | ERPs',
            'mock': '/images/services/web-service.png',
            'mockBottom': 'right-0 bottom-20 h-4/6'
        },
        {
            'name': 'Mobile app development',
            'description': 'Embrace convenience and connectivity with a robust mobile presence. Elevate your business objectives with tailor-made mobile apps, optimizing processes, expanding your audience reach, and enhancing user experience.',
            'categories': 'Android | React Native | Flutter | iOS',
            'mock': '/images/services/app-service.png',
            'mockBottom': 'right-20 bottom-0 h-5/6',
        },
        {
            'name': 'UI/UX and brand design',
            'description': 'We\'re enthusiastic about technology, continuous learning, and knowledge-sharing in the digital realm. As diligent problem-solvers, we adeptly navigate technical and business challenges, ensuring the creation of exceptional digital products and experiences.',
            'mock': '/images/services/ui-service.png',
            'categories': 'Brand Design | User Interface Design | User Experience',
            'mockBottom': 'right-0 bottom-20 h-2/3'
        },
        {
            'name': 'Gen AI integration',
            'description': 'Stay ahead of the curve and embrace Gen AI technology with custom-built solutions that offer increased security, efficiency, and transparency in your business operations.',
            'mock': '/images/services/ai-service.png',
            'categories': 'ChatGPT Integration | Gemini Integration',
            'mockBottom': 'right-20 bottom-20 h-2/3'
        },
    ];
    return (
        <div className="w-full mx-auto p-4 lg:px-12 mt-10 gap-12 flex flex-col justify-center">
            {services.map((service, index) => (
                <ServiceCard
                    key={index}
                    name={service.name}
                    categories={service.categories}
                    description={service.description}
                    mock={service.mock}
                    mockBottom={service.mockBottom}
                />
            ))}
        </div>
    );
}

export default ServicesList;