const PrivacyHeader = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 pt-12 pb-20 gap-4 flex flex-col justify-center bg-dark text-white">
            <div className="sm:text-4xl text-3xl font-semibold sm:w-1/2 w-full">
                Privacy Policy
            </div>
            <div className="text-base sm:w-3/4 w-full">Before proceeding, please take a moment to review our Privacy Policy. Your privacy and security are important to us. By accessing our website or using our services, you agree to abide by the terms outlined in our Privacy Policy.</div>
        </div>
    );
}

export default PrivacyHeader;