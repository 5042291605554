const PrivacyPolicy = () => {
    return (
        <div class="container mx-auto px-4 py-8">
            <h2 class="text-lg font-semibold mb-2">1. Information We Collect</h2>
            <p class="mb-4">We collect personal information that you provide to us voluntarily when you use our services or interact with our website. This may include your name, email address, and any other information you choose to provide.</p>

            <h2 class="text-lg font-semibold mb-2">2. How We Use Your Information</h2>
            <p class="mb-4">We use the information we collect to provide and improve our services, communicate with you, and personalize your experience. We may also use your information to send you promotional materials or updates about our products and services.</p>

            <h2 class="text-lg font-semibold mb-2">3. Information Sharing</h2>
            <p class="mb-4">We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third-party service providers who assist us in operating our website or conducting our business.</p>

            <h2 class="text-lg font-semibold mb-2">4. Data Security</h2>
            <p class="mb-4">We take the security of your personal information seriously and take measures to protect it from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.</p>

            <h2 class="text-lg font-semibold mb-2">5. Changes to This Policy</h2>
            <p class="mb-4">We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any changes.</p>

            <h2 class="text-lg font-semibold mb-2">6. Contact Us</h2>
            <p class="mb-4">If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@jibulabs.com">info@jibulabs.com</a>.</p>

            <p>Thank you for reviewing our Privacy Policy.</p>
        </div>
    );
}

export default PrivacyPolicy;