import ContactForm from "./Components/ContactUs/ContactForm";
import Awards from "./Components/Welcome/Awards";

const ContactUs = () => {
    return (
        <div className='select-none'>
            <Awards />
            <ContactForm/>
        </div>
    );
}

export default ContactUs;