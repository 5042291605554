/*
Hello Jibu Labs,

You got a contact request from:

Name:  {{from_name}}
Phone number: {{phone_number}}
company: {{company}}
Heard From: {{heard_from}}
Message For you: {{project_description}}


Best wishes,
Jibu Labs
*/

//validations

import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Toast } from 'primereact/toast';

const ContactUs = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [heardFrom, setHeardFrom] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    const [errors, setErrors] = useState({});

    const toast = useRef(null);

    const handleSubmitCall = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (!firstName) newErrors.firstName = 'First Name is required.';
        if (!phone) newErrors.phone = 'Phone is required.';
        if (!company) newErrors.company = 'Company is required.';
        if (!heardFrom) newErrors.heardFrom = 'Please select how you heard about us.';
        if (!projectDescription) newErrors.projectDescription = 'Message is required.';

        const phoneRegex = /^[+\d]+$/;
        if (phone && !phoneRegex.test(phone)) {
            newErrors.phone = 'Phone number can only contain numbers and + sign.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            alert('Please fill out all required fields.');
            return;
        }

        const serviceId = 'service_59lk0na';
        const templateId = 'template_1c9dusa'; 
        const userId = 'SRMiVIwbh3UlybTBy';

        const templateParams = {
            from_name: `${firstName} ${lastName}`,
            phone_number: phone,
            company: company,
            heard_from: heardFrom,
            project_description: projectDescription,
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then((response) => {
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setCompany('');
                setHeardFrom('');
                setProjectDescription('');
                setErrors({});

                toast.current.show({ severity: 'success', summary: 'Success', detail: 'We received your message and will connect shortly.', life: 3000 });
            })
            .catch((error) => {
                alert('Error sending email:', error);
            });
    };

    return (
        <div className="flex flex-col justify-center gap-0 my-4">
            <Toast ref={toast} /> {/* Render the Toast component */}
            <div className="w-full lg:px-12 px-6 flex flex-col justify-center text-white text-sm">
                <div className="bg-amber-500 h-3"></div>
            </div>
            <div className="bg-gray-100 sm:py-20 py-12 px-4 lg:px-12 grid sm:grid-cols-2 grid-cols-1 items-center">
                <div className="w-full mb-6 flex flex-col justify-center sm:gap-6 gap-4 text-dark text-sm">
                    <h2 className="text-3xl font-semibold tracking-tight">Send us a message</h2>
                    <div className="text-base font-medium w-full sm:w-3/4">
                        We appreciate your interest in Jibu Labs. Whether you have a specific project in mind or would like to learn more about what we do, drop us a line below.
                    </div>
                    <div className="uppercase text-sm font-semibold">Reach us at</div>
                    <div className="flex flex-col gap-2">
                        <div className="block"><a href="mailto:info@jibulabs.com" className="text-lg font-semibold">info@jibulabs.com</a></div>
                        <div className="block"><a href="tel:+18664717185" className="text-lg font-semibold">+1 (866) 471 7185</a></div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="font-semibold text-2xl">What happens after you send us a message?</div>
                        <div className="font-normal">A member of our team will be in touch within 24 hours.</div>
                    </div>
                </div>
                <div className="flex flex-col gap-4 sm:mt-0 mt-6 sm:w-5/6 justify-center items-center">
                    <div className="grid sm:grid-cols-2 gap-2 w-full">
                        <input
                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <input
                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
                    </div>
                    <div className="grid sm:grid-cols-2 gap-2 w-full">
                        <input
                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                        
                        <input
                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2"
                            placeholder="Company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            required
                        />
                        {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}
                        {errors.company && <span className="text-red-500 text-sm">{errors.company}</span>}
                    </div>
                    
                    <div className="w-full">
                        <select
                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2"
                            value={heardFrom}
                            onChange={(e) => setHeardFrom(e.target.value)}
                            required
                        >
                            <option disabled value="">How did you hear about us?</option>
                            <option value="referral">Referral</option>
                            <option value="instagram">Instagram</option>
                            <option value="twitter">Twitter</option>
                            <option value="facebook">Facebook</option>
                            <option value="google">Google Ads</option>
                        </select>
                        {errors.heardFrom && <span className="text-red-500 text-sm">{errors.heardFrom}</span>}
                    </div>
                    <div className="w-full">
                        <textarea
                            className="bg-gray-100 border border-gray-300 outline-amber-500 rounded w-full px-6 py-2 h-32"
                            placeholder="Message"
                            value={projectDescription}
                            onChange={(e) => setProjectDescription(e.target.value)}
                            required
                        ></textarea>
                        {errors.projectDescription && <span className="text-red-500 text-sm">{errors.projectDescription}</span>}
                    </div>
                    <button className="flex justify-center items-center bg-dark text-white w-full p-3 rounded shadow" onClick={handleSubmitCall}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;