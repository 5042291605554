import UilYoutube from '@iconscout/react-unicons/icons/uil-youtube'
import UilTwitter from '@iconscout/react-unicons/icons/uil-twitter'
import UilLinkedin from '@iconscout/react-unicons/icons/uil-linkedin'
import UilGithub from '@iconscout/react-unicons/icons/uil-github'
import UilInstagram from '@iconscout/react-unicons/icons/uil-instagram'
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const Footer = () => {

    const [ref, inView] = useInView({ triggerOnce: true })

    const date = new Date();

    const currentYear = date.getFullYear();

    return (
        <motion.div className="select-none p-6 lg:px-12 mt-6 lg:pb-6 text-black text-sm space-y-6" ref={ref} initial={{ y: 10, opacity: 0 }} animate={inView ? { y: 0, opacity: 1 } : {}} exit={{ y: 10, opacity: 0 }} transition={{ duration: 0.5 }}>
            <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 items-top justify-between">
                <div className="flex flex-col lg:w-3/12">
                    <div className='flex flex-row items-center space-x-2'>
                        <img src="/images/favicon.png" className="w-8 h-8 pointer-events-none" alt="Logo"></img>
                        <h2 className="font-extrabold text-2xl tracking-tight logo-font">Jibu Labs</h2>
                    </div>
                    <p className="text-base font-medium sm:py-6 py-4">
                        By leveraging our full spectrum strategy, design and technology effeciencies, we deliver game-changing outcomes for our clients worldwide.
                    </p>
                    <h3 className="text-lg font-semibold sm:py-4 py-2">Social Media</h3>
                    <div className="flex flex-row space-x-4">
                        <motion.a  href="https://www.linkedin.com/company/jibu-labs/" target="blank">
                            <UilLinkedin size={24}></UilLinkedin>
                        </motion.a>
                        <motion.a  href="https://twitter.com/jibulabs" target="blank">
                            <UilTwitter size={24}></UilTwitter>
                        </motion.a>
                        <motion.a  href="https://www.youtube.com/@JibuLabs" target="blank">
                            <UilYoutube size={24}></UilYoutube>
                        </motion.a>
                        <motion.a  href="https://github.com/Jibu-Labs" target="blank">
                            <UilGithub size={24}></UilGithub>
                        </motion.a>
                        <motion.a  href="https://www.instagram.com/jibulabs/" target="blank">
                            <UilInstagram size={24}></UilInstagram>
                        </motion.a>
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="text-base sm:mb-4 mb-0 font-bold">Services</div>
                    <ul className="list-none space-y-2 text-base">
                        <li>
                            <a href="/" className="font-semibold text-sm">Digital Product Development</a>
                        </li>
                        <li>
                            <a href="/" className="font-semibold text-sm">Staff Augmentation</a>
                        </li>
                        <li>
                            <a href="/" className="font-semibold text-sm">Data Analytics and Management</a>
                        </li>
                        <li>
                            <a href="/" className="font-semibold text-sm">Digital Strategy Consultation</a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="text-base sm:mb-4 mb-0 font-bold">Company</div>
                    <ul className="list-none space-y-2 text-base">
                        <li>
                            <a href="/company" className="font-semibold text-sm">About Us</a>
                        </li>
                        <li>
                            <a href="/commitments" className="font-semibold text-sm">Our Commitments</a>
                        </li>
                        <li>
                            <a href="/faqs" className="font-semibold text-sm">FAQs</a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="text-base sm:mb-4 mb-0 font-bold">Resources</div>
                    <ul className="list-none space-y-2 text-base">
                        <li>
                            <a href="/case-studies" className="font-semibold text-sm">Case Studies</a>
                        </li>
                        <li>
                            <a href="/insights" className="font-semibold text-sm">Insights</a>
                        </li>
                        <li>
                            <a href="/contact-us" className="font-semibold text-sm">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='border-b pb-6 border-slate-200'></div>

            <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-0 gap-4 font-semibold">
                <div className="text-sm lg:text-sm">&copy; {currentYear} Jibu Labs LLC | All Rights Reserved.</div>
                <div className="text-sm lg:text-sm sm:text-center text-start"></div>
                <ul className="flex flex-row sm:justify-end justify-between list-none lg:space-x-4">
                    <li className="text-sm lg:text-sm inline-block">
                        <a href="terms-of-service">Terms of Service</a>
                    </li>
                    <li className="text-sm lg:text-sm inline-block">
                        <a href="privacy-policy">Privacy Policy</a>
                    </li>
                </ul>
            </div>

        </motion.div>
    )
}

export default Footer