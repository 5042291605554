import ServicesHeader from './Components/Services/Header';
import Milestones from './Components/Services/Milestones';
import ServicesDescription from './Components/Services/ServicesDescription';
import ServicesList from './Components/Services/ServicesList';
import ExecutiveStrategy from './Components/Services/Strategy';
import CallUs from './Components/Welcome/CallUs';

const Services = () => {
  return (
    <div className='select-none'>
      <ServicesHeader/>
      <Milestones/>
      <ServicesDescription/>
      <ServicesList/>
      <ExecutiveStrategy/>
      <CallUs/>
    </div>
  )
}

export default Services;
