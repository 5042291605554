import entreprenista from './images/award-entreprenista.png';
import chaseInk from './images/chaseink.png';

const Awards = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 py-14 gap-4 flex flex-col justify-center text-black">
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-8 items-center">
                <div className="flex flex-col gap-4">
                    <h2 className="text-3xl font-semibold tracking-tight">Awards and Recognitions</h2>
                    <div className="text-base font-medium w-full">
                        We've channeled our way into the top ranks over time with purpose-driven solutions through digital product engineering and our continued commitment to delivering software excellence.
                    </div>
                </div>
                <div className="flex flex-row items-center gap-4 justify-between flex-nowrap">
                    <img src={entreprenista} className="sm:h-32 h-20 w-auto pointer-events-none" alt="award"></img>
                    <div className='sm:text-base text-sm font-bold uppercase'>Presented by</div>
                    <img src={chaseInk} className="sm:h-20 h-16 w-auto pointer-events-none" alt="chase"></img>
                </div>
            </div>
        </div>
    );
}

export default Awards;