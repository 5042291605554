import { useState } from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  function showHamburger() {
    setIsHamburgerOpen(!isHamburgerOpen);
  }

  return (
    <nav
      className="flex flex-row items-center justify-between flex-nowrap px-6 lg:px-12 py-8 w-full mx-auto bg-dark"
    >
      <Link
        to="/"
        className="flex flex-row items-center flex-shrink-0 text-white space-x-2"
      >
        <img src="/images/favicon.png" className="w-8 h-8" alt="Logo"></img>
        <h1 className="font-extrabold text-2xl tracking-tight logo-font">Jibu Labs</h1>
      </Link>

      <div className="sm:hidden block text-white cursor-pointer" onClick={showHamburger}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </div>

      {isHamburgerOpen && (
        <div className="fixed top-0 right-0 h-screen w-full max-w-full bg-dark z-50 flex flex-col items-center justify-center bg-white sm:hidden">
          <button
            onClick={() => setIsHamburgerOpen(false)}
            className="absolute top-6 right-6 text-black cursor-pointer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
          <Link
            to="/company"
            className="block my-2 font-semibold text-lg"
            onClick={() => setIsHamburgerOpen(false)}
          >
            Company
          </Link>
          <Link
            to="/services"
            className="block my-2 font-semibold text-lg"
            onClick={() => setIsHamburgerOpen(false)}
          >
            Services
          </Link>
          <Link
            to="/commitments"
            className="block my-2 font-semibold text-lg"
            onClick={() => setIsHamburgerOpen(false)}
          >
            Commitments
          </Link>
          <Link
            to="/case-studies"
            className="block my-2 font-semibold text-lg"
            onClick={() => setIsHamburgerOpen(false)}
          >
            Case Studies
          </Link>
          <Link
            to="/contact-us"
            className="block my-2 font-semibold text-lg"
            onClick={() => setIsHamburgerOpen(false)}
          >
            Get In Touch
          </Link>
        </div>
      )}

      <div className="lg:flex md:hidden hidden flex-row items-center gap-6 text-sm text-white">
        <Link
          to="/company"
          className="block lg:inline-block lg:mt-0"
        >
          Company
        </Link>
        <Link
          to="/services"
          className="block lg:inline-block lg:mt-0"
        >
          Services
        </Link>
        <Link
          to="/commitments"
          className="block lg:inline-block lg:mt-0"
        >
          Commitments
        </Link>
        <Link
          to="/case-studies"
          className="block lg:inline-block lg:mt-0"
        >
          Case Studies
        </Link>
      </div>
      <div className="lg:flex md:hidden hidden flex-row items-center gap-6 text-sm text-white">
        <Link to="/contact-us">
          <button className='border border-white rounded px-5 py-3 text-white font-bold tracking-tight'>
            Get in touch
          </button>
        </Link>
      </div>


    </nav >
  );
}
