const SocioEnvironment = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 sm:py-12 py-10 gap-4 flex flex-col justify-center bg-gray-100 text-black">
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-12">
                <div className="flex flex-col gap-4">
                    <div className="text-3xl font-semibold w-full">
                        Community
                    </div>
                    <div className="text-base w-full">At Jibu Labs, we believe in the power of giving back to the community. Through various initiatives and partnerships, we strive to make a positive impact and uplift those around us. From volunteering at local charities to sponsoring community events, we are committed to supporting causes that align with our values and contribute to the well-being of our community.</div>
                    <div className="text-base w-full">Additionally, we offer internships and mentorship programs to students, providing them with valuable experience and guidance as they embark on their career journeys. Through these efforts, we aim to create a more inclusive, resilient, and thriving community for all.</div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="text-3xl font-semibold w-full">
                        Environment
                    </div>
                    <div className="text-base w-full">Environmental care is at the forefront of our priorities. We are dedicated to minimizing our ecological footprint and promoting sustainable practices in all aspects of our operations. From reducing energy consumption and waste in our offices to implementing eco-friendly technologies in our products and services, we are committed to protecting the planet for future generations.</div>
                    <div className="text-base w-full">Furthermore, we actively seek out partnerships with environmentally conscious suppliers and organizations, and we continuously strive to innovate and develop solutions that address environmental challenges. By leading by example and advocating for environmental stewardship, we aim to inspire positive change within our industry and beyond.</div>
                </div>
            </div>
        </div>
    );
}

export default SocioEnvironment;