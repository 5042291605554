import ContactForm from "./Components/ContactUs/ContactForm";
import TermsHeader from "./Components/Terms/Header";
import TermsOfService from "./Components/Terms/Terms";
import Awards from "./Components/Welcome/Awards";

const Terms = () => {
    return (
        <div className="select-none">
            <TermsHeader />
            <TermsOfService />
            <Awards />
            <ContactForm />
        </div>
    );
}

export default Terms;