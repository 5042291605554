import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import reportWebVitals from './reportWebVitals';
import './index.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";


import Layout from './Pages/Layout';
import Error404 from './Pages/Error404';
import Welcome from './Pages/Welcome';
import Company from './Pages/Company';
import Services from './Pages/Services';
import Commitments from './Pages/Commitments';
import CaseStudies from './Pages/CaseStudies';
import ContactUs from './Pages/ContactUs';
import FAQ from './Pages/FAQ';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Pricing from './Pages/Pricing';
import CaseStudyDetails from './Pages/CaseStudyDetails';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Welcome />} />
          <Route path="/company" element={<Company />} />
          <Route path="/services" element={<Services />} />
          <Route path="/commitments" element={<Commitments />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-study/:id" element={<CaseStudyDetails />}/>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faqs" element={<FAQ/>} />
          <Route path="/terms-of-service" element={<Terms/>} />
          <Route path="/privacy-policy" element={<Privacy/>} />
          <Route path="*" element={< Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App></App>
  </React.StrictMode>
);
reportWebVitals();
