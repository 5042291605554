const TermsOfService = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-lg font-semibold mb-2">1. Use of Our Platform</h2>
            <ul className="list-disc pl-4 mb-4">
                <li>You must be at least 18 years old to use our platform.</li>
                <li>You agree to provide accurate and up-to-date information when using our platform.</li>
                <li>You are responsible for maintaining the security of your account and password.</li>
            </ul>

            <h2 className="text-lg font-semibold mb-2">2. Intellectual Property</h2>
            <p className="mb-4">All content on our platform, including but not limited to text, graphics, logos, images, and software, is the property of Jibu Labs or its licensors and is protected by copyright laws.</p>

            <h2 className="text-lg font-semibold mb-2">3. User Conduct</h2>
            <ul className="list-disc pl-4 mb-4">
                <li>You agree not to use our platform for any unlawful purpose or to violate any laws.</li>
                <li>You may not engage in any activity that interferes with the proper functioning of our platform or disrupts the experience of other users.</li>
            </ul>

            <h2 className="text-lg font-semibold mb-2">4. Limitation of Liability</h2>
            <p className="mb-4">Jibu Labs and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our platform.</p>

            <h2 className="text-lg font-semibold mb-2">5. Changes to Terms</h2>
            <p className="mb-4">Jibu Labs reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our platform. Your continued use of our platform after such modifications constitutes your acceptance of the revised Terms.</p>

            <h2 className="text-lg font-semibold mb-2">6. Governing Law</h2>
            <p className="mb-4">These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

            <h2 className="text-lg font-semibold mb-2">7. Contact Us</h2>
            <p className="mb-4">If you have any questions about these Terms, please contact us at <a href="mailto:contact@jibulabs.com">contact@jibulabs.com</a>.</p>

            <p>Thank you for reviewing our Terms of Service. By using our platform, you agree to these Terms.</p>
        </div>
    );
}

export default TermsOfService;