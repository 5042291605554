const VisionMission = () => {
    return (
        <div className="w-full mx-auto p-6 lg:px-12 sm:py-16 sm:mt-0 mt-12 pt-4 pb-8 flex flex-col justify-center text-sm">
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-12 items-center">
                <div className="flex flex-col gap-4">
                    <h2 className="sm:text-3xl text-2xl font-semibold tracking-tight">Our Vision</h2>
                    <div className="font-normal text-base sm:w-5/6">Our vision is to become the foremost partner for digital strategy, consultation, and implementation in the Industry 4.0 landscape. By delivering highly scalable technology solutions, we aim to propel our clients towards unprecedented growth and success.</div>
                </div>
                <div className="flex flex-col gap-4">
                    <h2 className="sm:text-3xl text-2xl font-semibold tracking-tight">Our Mission</h2>
                    <div className="font-normal text-base sm:w-5/6">At the heart of everything we do lies our vibrant culture, built upon a relentless pursuit of growth and anchored in values of openness, diversity, and unwavering honesty. Our essence drives agile creation of client-centered, innovative digital solutions.</div>
                </div>
            </div>
        </div>
    );
}

export default VisionMission;