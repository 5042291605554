import Awards from './Components/Welcome/Awards';
import CallUs from './Components/Welcome/CallUs';
import CaseStudies from './Components/Welcome/CaseStudies';
import CaseStudyIntro from './Components/Welcome/CaseStudyIntro';
import ContactUs from './Components/Welcome/ContactUs';
import Hero from './Components/Welcome/Hero'
import Partners from './Components/Welcome/Partners';
import ServicesIntro from './Components/Welcome/ServicesIntro';
import ServicesTabs from './Components/Welcome/ServicesTabs';
import TestimonialsTabs from './Components/Welcome/TestimonialsTabs';

const Welcome = () => {
  return (
    <div className='select-none'>
      <Hero/>
      <Partners/>
      <ServicesIntro/>
      <ServicesTabs/>
      <CallUs/>
      <CaseStudyIntro/>
      <CaseStudies/>
      <Awards/>
      <TestimonialsTabs/>
      <ContactUs/>
    </div>
  )
}



export default Welcome;
