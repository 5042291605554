const TermsHeader = () => {
    return (
        <div className="w-full mx-auto p-4 lg:px-12 pt-12 pb-20 gap-4 flex flex-col justify-center bg-dark text-white">
            <div className="sm:text-4xl text-3xl font-semibold sm:w-1/2 w-full">
                Terms of Service
            </div>
            <div className="text-base sm:w-3/4 w-full">Please review our Terms of Service before proceeding. By accessing our website or using our services, you agree to abide by these terms. Questions? Contact us. Thank you for choosing Jibulabs.</div>
        </div>
    );
}

export default TermsHeader;