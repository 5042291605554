import AllCaseStudies from "./Components/CaseStudies/CaseStudies";
import CaseStudiesHeader from "./Components/CaseStudies/Header";
import Awards from "./Components/Welcome/Awards";
import CallUs from "./Components/Welcome/CallUs";

const CaseStudies = () => {
    return (
        <div className="select-none">
            <CaseStudiesHeader/>
            <AllCaseStudies/>
            <Awards/>
            <CallUs/>
        </div>
    );
}

export default CaseStudies;