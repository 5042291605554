import { Link } from "react-router-dom";

const CaseStudyCard = (props) => {
    return (
        <div className="relative">
            <div className={`${props.backgroudColor} sm:px-16 sm:py-10 p-10 shadow-lg`}>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 items-center justify-center">
                    <img src={props.logo} className="w-auto h-12 pointer-events-none" alt="Logo"></img>
                    <div className="flex flex-row items-center gap-2 font-bold sm:justify-end">
                        {props.tags.map((tag, index) => (
                            <div
                                key={index}
                                className={`${props.textColor} flex flex-row items-center gap-2 flex-nowrap`}>
                                <div className="capitalize z-1000">{tag}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 sm:pt-12 pt-6 pb-8 items-center">
                    <div className={`${props.textColor} flex flex-col gap-4`}>
                        <h2 className="text-3xl font-semibold capitalize">{props.company}</h2>
                        <div className="font-normal sm:text-base text-sm">{props.description}</div>
                        <div className="block">
                            <Link to={props.url}>
                                <button className='bg-white shadow-lg rounded-lg mt-4 px-5 py-3 text-black font-semibold sm:text-base text-sm tracking-tight'>
                                    View Case Study
                                </button>
                            </Link>
                        </div>
                    </div>
                    <img src={props.mock} className={`sm:block hidden absolute right-0 ${props.mockBottom} w-auto pointer-events-none`} alt="mockup" />
                </div>
            </div>
        </div>
    );
}

const AllCaseStudies = () => {
    const studies = [
        {
            'logo': '/images/clients/getwitit.png',
            'company': 'GetWitIT',
            'description': 'GetWITit is a non-profit organization, with the specific mission of addressing the declining number of women in the information systems and computing industries, through programs that promote awareness, address attrition, create opportunities to transition to tech, and preparation for leadership.',
            'url': '#',
            'tags': ['web development', '|', 'UI/UX'],
            'tagIconColor': 'bg-black',
            'background': 'bg-gradient-to-r from-gray-100 to-gray-50',
            'textColor': 'text-black',
            'mock': '/images/clients/mock1.jpg',
            'mockBottom': 'bottom-10 h-2/3',
        },
        {
            'logo': '/images/clients/kresus.png',
            'company': 'Kresus',
            'description': 'Kresus is the first Crypto & NFT wallet offering password-free total recoverability. Never fear getting locked out of your wallet again. Designed with security and convenience in mind, Kresus is your go-to web3 SuperApp for the safest and easiest crypto experience.',
            'url': '#',
            'tags': ['Android', '|', 'UI/UX'],
            'tagIconColor': 'bg-white',
            'background': 'bg-gradient-to-r from-[#020973] to-[#0C0C26]',
            'textColor': 'text-white',
            'mock': '/images/clients/mock-2.svg',
            'mockBottom': 'bottom-0 h-96'
        },
        {
            'logo': '/images/clients/myhotlogo.png',
            'company': 'My Hot Sale',
            'description': 'Our team worked on the my Hot Sale e-commerce platform for a client, focusing on creating a robust and scalable online shopping experience. We handled both the technical development and the UI/UX design to ensure the platform not only functions seamlessly but also delivered an exceptional user experience.',
            'url': '#',
            'tags': ['E-commerce', '|', 'UI/UX'],
            'tagIconColor': 'bg-black',
          'background': 'bg-gradient-to-r from-gray-100 to-gray-50',
            'textColor': 'text-black',
            'mock': '/images/clients/myhotsale.png',
            'mockBottom': 'bottom-10 right-10 h-80'
        },
        {
            'logo': '/images/clients/budgeting-buddy.png',
            'company': 'Budgeting Buddy',
            'description': 'Budgeting Buddy is a simple App meant to help maintain your monthly and daily budgets, the app is free, and you should be able to enjoy all free features without problems. To set up your wallet, visit the income section, and add amount. The app has over 10,000 downloads on Google Play',
            'url': '#',
            'tags': ['Android', '|', 'iOS', '|', 'UI/UX'],
            'tagIconColor': 'bg-black',
            'background': 'bg-[#E8EAF7]',
            'textColor': 'text-black',
            'mock': '/images/clients/budgeting-buddy-mock.png',
            'mockBottom': 'bottom-10 right-20 h-80'
        },
        {
            'logo': '/images/clients/logo_wellness.png',
            'company': 'My-Wellness',
            'description': 'MyWellness, is a trusted platform for therapy services across Africa. At MyWellness, they provide personalized therapy sessions through video and audio, making it easier for clients to access professional support from anywhere. Whether you\'re looking for mental health assistance or counseling, their platform offers seamless therapy options via their website and mobile app',
            'url': '#',
            'tags': ['Consultancy', '|', 'Features'],
            'tagIconColor': 'bg-black',
            'background': 'bg-[#4c92bd]',
            'textColor': 'text-white',
            'mock': '/images/clients/mywellness.png',
            'mockBottom': 'bottom-10 right-10 h-80'
        },
        {
            'logo': '/images/clients/ladora-logo-img.png',
            'company': 'Ladora Cuisine',
            'description': 'Located at the heart of New York, Ladora Cuisine is a personal chef who offers catering services. Ladora Cuisine is a renowned culinary service provider committed to delivering exceptional dining experiences tailored to the unique preferences and dietary requirements of its esteemed clients.',
            'url': '#',
            'tags': ['Website', '|', 'UI/UX'],
            'tagIconColor': 'bg-black',
            'background': 'bg-[#F3E7D9]',
            'textColor': 'text-black',
            'mock': '/images/clients/ladora-cuisine.png',
            'mockBottom': 'bottom-10 right-20 h-80'
        },
        
        {
            'logo': '/images/clients/logo-lg.png',
            'company': 'Mbaitu Inc',
            'description': 'Mbaitu Inc. brings together the diaspora community from South Eastern Kenya and others from the region who immigrated from other parts of Kenya to the USA and Canada. Mbaitu seeks to network members, create learning opportunities which will enable us to thrive in the diaspora and build a bridge between us and our three counties (Makueni, Machakos & Kitui).',
            'url': '#',
            'tags': ['Website', '|', 'UI/UX'],
            'tagIconColor': 'bg-black',
            'background': 'bg-[#E8EAF7]',
            'textColor': 'text-black',
            'mock': '/images/clients/mbaituinc.png',
            'mockBottom': 'bottom-10 right-10 h-80'
        },
      
    ];
    return (
        <div className="w-full mx-auto p-4 lg:px-12 mt-10 gap-12 flex flex-col justify-center">
            {studies.map((study, index) => (
                <CaseStudyCard
                    key={index}
                    logo={study.logo}
                    company={study.company}
                    description={study.description}
                    url={study.url}
                    tags={study.tags}
                    backgroudColor={study.background}
                    textColor={study.textColor}
                    mock={study.mock}
                    mockBottom={study.mockBottom}
                />
            ))}
        </div>
    );
}

export default AllCaseStudies;