import globe from "./images/world.svg";
import mobile from "./images/mobile.svg";
import strategy from "./images/strategy.svg";
import team from "./images/team.svg";
import analytics from "./images/analytics.svg";
import { useState } from "react";

const TabOne = (props) => {
    return (
        <div className="relative">
            <img src={globe} className="pointer-events-none absolute sm:-top-24 top-0 -left-36 sm:w-1/3 w-full h-auto opacity-10" alt="Globe"></img>
            <img src={mobile} className="pointer-events-none absolute sm:-top-24 -top-16 right-0 sm:w-1/4 w-1/2 h-auto " alt="Mobile"></img>
            <div className="flex flex-col gap-6 p-6 text-white">
                <div className="text-white opacity-40">01</div>
                <h2 className="text-3xl sm:w-full w-1/2 capitalize font-semibold">{props.label}</h2>
                <p className="sm:w-3/4 pr-6 text-sm sm:text-base w-full font-normal">We specialize in agile digital product development, ensuring market-ready solutions tailored to your business. Our experienced team collaborates closely with you from concept to delivery, prioritizing efficiency and quality to keep you ahead in today's fast-paced digital landscape.</p>
                <div className="flex sm:flex-row flex-col sm:items-center items-start gap-2 sm:mt-6 sm:w-3/4 w-full">
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="block sm:hidden bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Website Development</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Mobile App Development</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">System and ERPs</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Gen AI Consultancy</div>
                    </div>
                </div>
                <a href="/services" className="mt-4">
                    <button className="flex flex-row gap-1 items-center border border-white text-white px-4 py-3 rounded text-base">
                        <span className="text-sm">Learn More</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </a>
            </div>
        </div>

    );
}

const TabTwo = (props) => {
    return (
        <div className="relative">
            <img src={globe} className="pointer-events-none absolute sm:-top-24 top-0 -left-36 sm:w-1/3 w-full h-auto opacity-10" alt="Globe"></img>
            <img src={analytics} className="pointer-events-none absolute sm:-top-24 -top-16 right-0 sm:w-1/4 w-1/2 h-auto " alt="Analytics"></img>
            <div className="flex flex-col gap-6 p-6 text-white">
                <div className="text-white opacity-40">02</div>
                <h2 className="text-3xl sm:w-full w-1/2 capitalize font-semibold">{props.label}</h2>
                <p className="sm:w-3/4 pr-6 text-sm sm:text-base w-full font-normal">We specialize in advanced data analytics and management, delivering tailored solutions to optimize your business processes. Our expert team collaborates closely with you to harness the power of data, enabling informed decision-making and unlocking growth opportunities. From collection to visualization, we prioritize efficiency and quality to keep your business at the forefront of data-driven excellence.</p>
                <div className="flex sm:flex-row flex-col sm:items-center items-start gap-2 sm:mt-6 sm:w-3/4 w-full">
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="block sm:hidden bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Machine Learning (ML)</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Internet of Things (IOT)</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Big Data</div>
                    </div>
                </div>
                <a href="/services" className="mt-4">
                    <button className="flex flex-row gap-1 items-center border border-white text-white px-4 py-3 rounded text-base">
                        <span className="text-sm">Learn More</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </a>
            </div>
        </div>

    );
}

const TabThree = (props) => {
    return (
        <div className="relative">
            <img src={globe} className="pointer-events-none absolute sm:-top-24 top-0 -left-36 sm:w-1/3 w-full h-auto opacity-10" alt="Globe"></img>
            <img src={strategy} className="pointer-events-none absolute sm:-top-24 -top-16 right-0 sm:w-1/4 w-1/2 h-auto " alt="Strategy"></img>
            <div className="flex flex-col gap-6 p-6 text-white">
                <div className="text-white opacity-40">03</div>
                <h2 className="text-3xl sm:w-full w-1/2 capitalize font-semibold">{props.label}</h2>
                <p className="sm:w-3/4 pr-6 text-sm sm:text-base w-full font-normal">Our digital strategy consultation service provides tailored guidance to navigate the complex digital landscape with confidence. Leveraging industry expertise, we craft strategic roadmaps aligned with your objectives for sustainable growth. From market analysis to brand positioning, we offer actionable insights to keep you ahead. Partner with us to unlock your digital potential and chart a course for success.</p>
                <div className="flex sm:flex-row flex-col sm:items-center items-start gap-2 sm:mt-6 sm:w-3/4 w-full">
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="block sm:hidden bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Strategic Roadmap Development</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Market Analysis and Competitor Research</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Customer Journey Mapping</div>
                    </div>
                </div>
                <a href="/services" className="mt-4">
                    <button className="flex flex-row gap-1 items-center border border-white text-white px-4 py-3 rounded text-base">
                        <span className="text-sm">Learn More</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </a>
            </div>
        </div>

    );
}

const TabFour = (props) => {
    return (
        <div className="relative">
            <img src={globe} className="pointer-events-none absolute sm:-top-24 top-0 -left-36 sm:w-1/3 w-full h-auto opacity-10" alt="Globe"></img>
            <img src={team} className="pointer-events-none absolute sm:-top-24 -top-16 -right-10 sm:w-1/3 w-1/2 h-auto " alt="Team"></img>
            <div className="flex flex-col gap-6 p-6 text-white">
                <div className="text-white opacity-40">04</div>
                <h2 className="text-3xl sm:w-full w-1/2 capitalize font-semibold">{props.label}</h2>
                <p className="sm:w-3/4 pr-6 text-sm sm:text-base w-full font-normal">Our staff augmentation service offers flexible solutions for your evolving workforce needs. Whether short-term projects or ongoing initiatives, access highly skilled professionals who seamlessly integrate with your team. From developers to project managers, we deliver top talent to drive project success. Augment your team on-demand, reduce hiring overheads, and maintain full control with our service.</p>
                <div className="flex sm:flex-row flex-col sm:items-center items-start gap-2 sm:mt-6 sm:w-3/4 w-full">
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="block sm:hidden bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Full-Stack Developers</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">UI/UX Designers</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">React Developers</div>
                    </div>
                    <div className="flex items-center flex-nowrap gap-2">
                        <div className="bg-white p-0.5 rounded"></div>
                        <div className="text-sm font-semibold uppercase tracking-wide">Backend Developers</div>
                    </div>
                </div>
                <a href="/services" className="mt-4">
                    <button className="flex flex-row gap-1 items-center border border-white text-white px-4 py-3 rounded text-base">
                        <span className="text-sm">Learn More</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </a>
            </div>
        </div>

    );
}

const ServicesTabs = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabs = ['digital product development', 'data analytics & management', 'digital strategy consultation', 'tech staff augmentation'];

    const handleTabClick = (index) => {
        setActiveTab(index);
    }

    return (
        <div className="w-full mx-auto lg:px-12 p-4 gap-4 flex flex-col justify-center">
            <div className="w-full grid sm:grid-cols-4 grid-cols-2 sm:gap-2 gap-0 sm:items-center items-start justify-between border border-gray-300 rounded-lg p-1">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => handleTabClick(index)}
                        className={`flex flex-nowrap sm:text-base text-sm items-center sm:gap-2 gap-0 px-2 py-3 rounded capitalize font-semibold ${index === activeTab ? 'bg-dark text-white' : 'bg-white'}`}
                    >
                        {index === 0 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>
                        }
                        {index === 1 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                        </svg>
                        }
                        {index === 2 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                        </svg>
                        }
                        {index === 3 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                        </svg>
                        }
                        {tab}
                    </button>
                ))}
            </div>
            <div className="sm:my-12 my-4 bg-dark rounded-lg">
                {activeTab === 0 && <div className="p-6 text-white"><TabOne label="Digital Product Development"></TabOne></div>}
                {activeTab === 1 && <div className="p-6 text-white"><TabTwo label="Data Analytics & Management"></TabTwo></div>}
                {activeTab === 2 && <div className="p-6 text-white"><TabThree label="Digital Strategy Consultation"></TabThree></div>}
                {activeTab === 3 && <div className="p-6 text-white"><TabFour label="Tech Staff Augmentation"></TabFour></div>}
            </div>
        </div>
    );
}

export default ServicesTabs;