const ExecutiveStrategy = () => {
    return (
        <div className="w-full mx-auto p-4 sm:px-12 sm:py-20 gap-4 flex flex-col justify-center text-black">
            <h2 className="text-3xl font-semibold tracking-tight sm:w-1/2 w-full">Executive Strategy.</h2>
            <div className="text-base font-medium w-full">
                Jibu Labs empowers clients with Executive Strategy through our Fractional CTO service. This offering provides dedicated technical expertise and strategic guidance on demand, offering numerous advantages, especially for startups, small businesses, and organizations not in need of a full-time CTO.
            </div>
            <div className="text-base font-medium w-full">
            By leveraging a Fractional CTO, clients can enhance technology utilization, streamline processes, and align technology initiatives more effectively, ultimately boosting efficiency and productivity. This service suits clients seeking sporadic access to comprehensive technical expertise, bridging the gap between commercial, operational, and technical requirements seamlessly.
            </div>
        </div>
    );
}

export default ExecutiveStrategy;